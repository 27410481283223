import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBy } from 'lodash';
import Loading from 'src/components/0100_loading';
import useEvent from 'src/hooks/events/useEvent';
import useEventTickets from 'src/hooks/events/useEventTickets';
import Table from 'src/components/0100_table';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import useEventAttendees from 'src/hooks/events/useEventAttendees';
import Currency from 'src/components/0100_currency';
import TicketGroup from './TicketGroup';
import Search from './Search';
import { TOnFilterChange } from './TicketGroup/Ticket/TicketAttendees';
import ticketRevenue from '../utils/ticketRevenue';
import ShowRevenue from './ShowRevenue';

const restrictionSort: { [key: string]: number } = {
  new_player_restricted: 0,
  unrestricted: 10,
  assistant_restricted: 20,
  employee_restricted: 30,
};

const ByTicket: FC = () => {
  const { event } = useEvent();
  const methods = useForm({
    defaultValues: {
      searchQuery: '',
      showRevenue: false,
    },
  });
  const { watch } = methods;
  const { showRevenue, searchQuery } = watch();
  const [ expandedTicketIds, setExpandedTicketIds ] = useState<number[]>([]);
  const { tickets, fetching } = useEventTickets({ editMode: true });
  const [ searchMatches, setSearchMatches ] = useState<{ [key: string]: number }>(
    {},
  );
  const { attendees, refetch } = useEventAttendees({});
  const restrictionGroups = groupBy( tickets, 'restrictionType' );
  const handleExpand = useCallback(( id: number ) => {
    setExpandedTicketIds( x =>
      x.includes( id ) ? x.filter( y => y !== id ) : [ ...x, id ],
    );
  }, []);
  const handleExpandAll = useCallback(() => {
    if ( expandedTicketIds.length === 0 ) {
      setExpandedTicketIds( tickets.map( x => x.id ));
    } else {
      setExpandedTicketIds([]);
    }
  }, [ expandedTicketIds.length, tickets ]);
  const partialExpanded = expandedTicketIds.length > 0;
  const hasSearchQuery = searchQuery.trim().length > 0;
  const unfilteredAttendancesLength = tickets
    .map( x => x.eventAttendeesCount )
    .reduce(( a, b ) => ( a ?? 0 ) + ( b ?? 0 ), 0 );
  const filteredAttendancesLength = useMemo(
    () => Object.values( searchMatches ).reduce(( a, b ) => a + b, 0 ),
    [ searchMatches ],
  );
  const handleFilterChange = useCallback(
    ({ identifier, length }: TOnFilterChange ) => {
      setSearchMatches( x => ({
        ...x,
        [identifier ?? '']: length,
      }));
    },
    [],
  );
  const {
    attendeesCount,
    paidAttendeesCount,
    baseRevenue,
    extraXpRevenue,
    totalRevenue,
  } = useMemo(
    () =>
      ticketRevenue({
        attendees,
        extraXpCost: Number( event?.config.extraXpCost ),
      }),
    [ attendees, event?.config.extraXpCost ],
  );

  useEffect(() => {
    if ( hasSearchQuery ) {
      setExpandedTicketIds( tickets.map( x => x.id ));
    }
  }, [ handleExpandAll, hasSearchQuery, tickets ]);

  useEffect(() => {
    refetch();
  }, [ refetch ]);

  if ( fetching ) return <Loading />;

  return (
    <div className="grid gap-4">
      <FormProvider {...methods}>
        <div className="flex justify-center gap-4 items-center">
          <div>
            <Search />
          </div>
          <div>
            <ShowRevenue />
          </div>
        </div>

        <div className="flex justify-center">
          <Table
            className={clsx(
              'table-fixed',
              showRevenue
                ? 'min-w-[720px]'
                : 'w-[calc(100vw-8px)] sm:w-[480px] lg:w-[640px]',
            )}
            headers={[
              { content: 'Tickets' },
              { content: 'Price', className: 'w-[64px] text-center' },
              { content: 'Limit', className: 'w-[64px] text-center' },
              showRevenue
                ? {
                    key: 'paid',
                    content: (
                      <div>
                        <div className="font-normal opacity-75">Paid</div>
                        <div>{`${paidAttendeesCount}/${attendeesCount}`}</div>
                      </div>
                    ),
                    className: 'w-[64px] text-right',
                  }
                : undefined,
              showRevenue
                ? {
                    key: 'baseRevenue',
                    content: (
                      <div>
                        <div className="font-normal opacity-75">Base</div>
                        <Currency value={baseRevenue} />
                      </div>
                    ),
                    className: 'w-[64px] text-right',
                  }
                : undefined,
              showRevenue
                ? {
                    key: 'extraXpRevenue',
                    content: (
                      <div>
                        <div className="font-normal opacity-75">+XP</div>
                        <Currency value={extraXpRevenue} />
                      </div>
                    ),
                    className: 'w-[64px] text-right',
                  }
                : undefined,
              showRevenue
                ? {
                    key: 'totalRevenue',
                    content: (
                      <div>
                        <div className="font-normal opacity-75">Total</div>
                        <Currency value={totalRevenue} />
                      </div>
                    ),
                    className: 'w-[64px] text-right',
                  }
                : undefined,
              {
                key: 'attendanceCount',
                content: (
                  <div>
                    <FontAwesomeIcon icon={faUser} className="opacity-50" />
                    <div className="items-center justify-end w-full text-right gap-2 cursor-pointer">
                      {hasSearchQuery ? (
                        <div className="flex justify-end items-center">
                          {`${filteredAttendancesLength}/${unfilteredAttendancesLength}`}
                        </div>
                      ) : (
                        <div className="flex items-center justify-end gap-1.5">
                          {unfilteredAttendancesLength}
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={clsx(
                              'transition-all',
                              partialExpanded && '-scale-y-100',
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ),
                className: 'w-[64px] text-right pr-2',
                onClick: handleExpandAll,
              },
            ]}
          >
            {Object.keys( restrictionSort )
              .sort(( a, b ) => restrictionSort[a] - restrictionSort[b])
              .map( restrictionType => (
                <TicketGroup
                  key={restrictionType}
                  name={restrictionType}
                  adjustableShift={[
                    'new_player_restricted',
                    'unrestricted',
                  ].includes( restrictionType )}
                  expandedTicketIds={expandedTicketIds}
                  tickets={restrictionGroups[restrictionType] || []}
                  attendees={attendees.filter(
                    x => x.ticket.restrictionType === restrictionType,
                  )}
                  config={event?.config}
                  onExpand={handleExpand}
                  onFilterChange={handleFilterChange}
                />
              ))}
          </Table>
        </div>
      </FormProvider>
    </div>
  );
};

export default ByTicket;
