import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const MechanicsHeader: FC = () => {
  const { linkToOrganization, organizationSection, mechanicsSection } =
    useAppNavigations();

  return (
    <ResponsiveTabGroup label={startCase( mechanicsSection )}>
      <Tab
        label="Diseases"
        to={linkToOrganization({
          section: 'mechanics',
          subsection: 'diseases',
        })}
        highlightMode="responsive"
        isActive={
          organizationSection === 'mechanics' ||
          ( mechanicsSection ?? 'diseases' ) === 'diseases'
        }
      />
      <Tab
        label="Societies"
        to={linkToOrganization({
          section: 'mechanics',
          subsection: 'societies',
        })}
        highlightMode="responsive"
      />
    </ResponsiveTabGroup>
  );
};

export default MechanicsHeader;
