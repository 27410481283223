import clsx from 'clsx';
import { FC } from 'react';

interface IProps extends Intl.NumberFormatOptions {
  value: number;
}

const Currency: FC<IProps> = ({ value, ...props }) => (
  <div className={clsx( value === 0 && 'opacity-50' )}>
    {Intl.NumberFormat( 'en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      ...props,
    }).format( value )}
  </div>
);

export default Currency;
