import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps, useMatches } from 'react-router-dom';

interface IProps extends Omit<LinkProps, 'to'> {
  url: string;
  label?: string;
  className?: string;
  isActive?: boolean;
}

const SidebarLink: FC<PropsWithChildren<IProps>> = ({
  label,
  children,
  isActive: overrideIsActive,
  url,
  className,
  ...props
}) => {
  const routeMatches = useMatches();
  const defaultIsActive = !!routeMatches.find( x => x.pathname === url );
  const isActive =
    overrideIsActive !== undefined ? overrideIsActive : defaultIsActive;

  return (
    <div
      className={clsx(
        'border-l-4 transition-all duration-300 ease-in-out overflow-hidden',
        isActive
          ? 'border-juno-gray-50 text-juno-gray-50 text-shadow'
          : 'border-transparent hover:border-juno-gray-200 text-juno-gray-200 hover:text-juno-gray-50 hover:text-shadow',
      )}
    >
      <Link
        className={clsx( 'block px-2 py-1 text-left w-full', className )}
        to={url}
        {...props}
      >
        {children || label}
      </Link>
    </div>
  );
};
export default SidebarLink;
