import { FC, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faLock,
  faTimes,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash';
import clsx from 'clsx';

import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import Loading from 'src/components/0100_loading';
import useCharacterBuilderStore from 'src/stores/characterBuilder';
import useCharacter from 'src/hooks/characters/useCharacter';

interface IProps {
  showLock?: boolean;
}

const CharacterHeader: FC<IProps> = ({ showLock }) => {
  const { characterId, playerId, linkToCharacter, characterSection } =
    useAppNavigations();
  const { actions, isSafetyLockActive, isSaving, isSaved, error } =
    useCharacterBuilderStore();
  const { canDisableSafetyLock, canEditSensitiveData } = useCharacter({
    playerId: Number( playerId ),
    characterId: Number( characterId ),
  });

  useEffect(() => {
    actions.lock();
  }, [ characterId, actions ]);

  return (
    <div className="flex items-center gap-4">
      {isSaving && <Loading size="small" />}
      {isSaved && <FontAwesomeIcon icon={faCheck} />}
      {error && <FontAwesomeIcon icon={faTimes} className="animate-pulse" />}
      {showLock && (
        <button
          type="button"
          disabled={!canDisableSafetyLock}
          onClick={() => canDisableSafetyLock && actions.toggleLock()}
        >
          <FontAwesomeIcon
            icon={isSafetyLockActive ? faLock : faUnlock}
            className={clsx(
              canDisableSafetyLock
                ? 'text-juno-gray-50 cursor-pointer'
                : 'text-juno-gray-700 cursor-not-allowed',
            )}
          />
        </button>
      )}
      <ResponsiveTabGroup label={startCase( characterSection || 'build' )}>
        <Tab
          label="Build"
          to={linkToCharacter({ section: 'build' })}
          highlightMode="responsive"
          isActive={( characterSection || 'build' ) === 'build'}
        />
        <Tab
          label="Journal"
          to={linkToCharacter({ section: 'journal' })}
          highlightMode="responsive"
          isActive={characterSection === 'journal'}
        />
        <Tab
          label="XP Audit"
          to={linkToCharacter({ section: 'xp_audit' })}
          highlightMode="responsive"
          isActive={characterSection === 'xp_audit'}
        />
        {canEditSensitiveData && (
          <Tab
            label="Printout"
            to={linkToCharacter({ section: 'printout' })}
            highlightMode="responsive"
            isActive={characterSection === 'printout'}
          />
        )}
      </ResponsiveTabGroup>
    </div>
  );
};

export default CharacterHeader;
