import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { IItemGradeEnum, IItemKindEnum } from 'src/graphql/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import EmbossAndReproduction from '../EmbossAndReproduction';

import '../index.css';
import Security from '../Security';

interface IProps {
  id: number;
  name: string;
  kind: IItemKindEnum;
  grade: IItemGradeEnum;
  updatedAt: string;
  lifetimeAmount?: number | null;
  metadata: {
    printHeader?: string | null;
    mechanics?: string | null;
    notes?: string | null;
    requirementsToUse?: string | null;
    uses?: string | null;
  };
  itemCrafting: {
    id: number;
    craftingTimeInMinute?: number | null;
    craftingMindCost?: number | null;
    craftingZone?: string | null;
    craftingSkills?: string | null;
    craftingComponents: {
      id: number;
      component: {
        id: number;
        name: string;
      };
      amount: number;
    }[];
    craftingFinalProducts: {
      id: number;
      stack: number;
      finalProduct?: {
        id: number;
        name: string;
        grade: IItemGradeEnum;
        kind: IItemKindEnum;
        lifetimeAmount?: number | null;
        childItemClassifications: {
          id: number;
          childItem: {
            id: number;
            name: string;
            kind: IItemKindEnum;
            lifetimeAmount?: number | null;
            metadata: {
              mechanics?: string | null;
              requirementsToUse?: string | null;
              uses?: string | null;
            };
          };
        }[];
        metadata: {
          printHeader?: string | null;
          mechanics?: string | null;
          notes?: string | null;
          requirementsToUse?: string | null;
          uses?: string | null;
        };
      };
    }[];
  };
  itemReproduction?: {
    id: number;
    reproductionTimeInMinute?: number | null;
    reproductionMindCost?: number | null;
    reproductionSkills?: string | null;
    metadata: {
      notes?: string | null;
    };
    itemReproductionComponents: {
      id: number;
      amount: number;
      item: {
        id: number;
        name: string;
      };
    }[];
  };
}

const ItemCrafting: FC<IProps> = ({ ...blueprint }) => {
  const output = blueprint.itemCrafting.craftingFinalProducts[0]?.finalProduct;
  const outputKind =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications
          .map( x => startCase( x.childItem.kind ))
          .join( ' or ' )
      : startCase( output?.kind );
  const requirementsToUse =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications[0].childItem.metadata.requirementsToUse
      : output?.metadata.requirementsToUse;
  const lifetimeAmount =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications[0].childItem.lifetimeAmount
      : output?.lifetimeAmount;
  const uses =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications[0].childItem.metadata.uses
      : output?.metadata.uses;
  const outputMechanics =
    output && output.childItemClassifications.length > 0
      ? output.childItemClassifications.map(
          x =>
            `${startCase( x.childItem.kind )}: ${x.childItem.metadata.mechanics}`,
        )
      : [ output?.metadata.mechanics ];
  const { itemCrafting } = blueprint;
  const sortedCraftingComponents = useMemo(
    () =>
      itemCrafting.craftingComponents.sort(( a, b ) => {
        if ( a.amount === b.amount ) {
          a.component.name.localeCompare( b.component.name );
        }

        return b.amount - a.amount;
      }),
    [ itemCrafting ],
  );

  return (
    <div
      className={clsx(
        'printout blueprint border border-juno-gray-700 overflow-hidden',
        blueprint.metadata.printHeader === 'Artisan Recipe' && 'artisan',
        blueprint.metadata.printHeader === 'Culinary Recipe' && 'culinary',
        blueprint.metadata.printHeader?.match( /necrology/i ) && 'necrology',
        blueprint.metadata.printHeader?.match( /dubious/i ) && 'criminal',
      )}
    >
      <div className="printout-wrapper">
        <div className="border-b border-juno-gray-700 printout-header">
          {blueprint.metadata.printHeader}
        </div>
        <div className="border-b border-juno-gray-700 text-xl p-2">
          <table>
            <tbody>
              <tr>
                <td>Item Name</td>
                <td colSpan={3}>{output?.name}</td>
              </tr>
              <tr>
                <td>Item Type</td>
                <td colSpan={3}>{outputKind}</td>
              </tr>
              <tr>
                <td>Requirements To Use</td>
                <td colSpan={3}>{requirementsToUse}</td>
              </tr>
              <tr>
                <td>Expiration</td>
                <td>
                  {Number( lifetimeAmount ) > 0
                    ? `${lifetimeAmount} months`
                    : 'Does not expire'}
                </td>
                <td>
                  <span>Number of Uses: </span>
                  {uses || 'N/A'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="border-b border-juno-gray-700 p-2 h-[5.7in]">
          <div className="flex justify-between w-full">
            <div className="w-full">
              <table>
                <tbody>
                  <tr>
                    <td>Production Skills</td>
                    <td colSpan={3}>{itemCrafting.craftingSkills}</td>
                  </tr>
                  <tr>
                    <td>Production Time</td>
                    <td>{itemCrafting.craftingTimeInMinute} minutes</td>
                  </tr>
                  <tr>
                    <td>Production Cost</td>
                    <td>{itemCrafting.craftingMindCost} Mind</td>
                    <td>Production Zone</td>
                    <td>{itemCrafting.craftingZone}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Production Resources
                      <div className="font-normal">
                        {Array.from({ length: 9 }).map(( _, i ) => {
                          const craftingComponent = sortedCraftingComponents[i];
                          if ( !craftingComponent )
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <div key={i} className="text-transparent">
                                .
                              </div>
                            );
                          return (
                            <div
                              key={craftingComponent.id}
                              className="flex items-center gap-2 w-full"
                            >
                              <div className="w-12 text-right">
                                <span className="font-bold">
                                  {craftingComponent.amount}
                                </span>
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="pl-1 text-sm"
                                />
                              </div>
                              <div>{craftingComponent.component.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div className="pl-2 font-semibold text-xl">Item Mechanics</div>
            <div className="pl-2 text-sm min-h-[48px]">
              {outputMechanics.map(( x, i ) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="whitespace-pre-wrap"
                >
                  {x}
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="pl-2 font-semibold text-xl">Special Notes</div>
            <div className="pl-2 text-sm min-h-[48px] whitespace-pre-wrap">
              {blueprint.metadata.notes}
            </div>
          </div>
        </div>
        <EmbossAndReproduction itemReproduction={blueprint.itemReproduction} />
      </div>
      <div className="printout-footer" />
      <Security version={blueprint.updatedAt} />
    </div>
  );
};

export default ItemCrafting;
