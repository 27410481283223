import { ITicketDiscountEnum } from 'src/graphql/types';

type TAttendee = {
  paid: boolean;
  buildGrowth: number;
  ticket: {
    discountType: ITicketDiscountEnum;
    cost: number;
  };
};

const ticketRevenue = ({
  attendees,
  extraXpCost,
}: {
  attendees: TAttendee[];
  extraXpCost: number;
}) => {
  const paidAttendees = attendees.filter( x => x.paid );
  const baseRevenue = paidAttendees.reduce(( acc, x ) => acc + x.ticket.cost, 0 );
  const extraXpRevenue = paidAttendees.reduce(
    ( acc, x ) =>
      acc +
      ( x.ticket.discountType === ITicketDiscountEnum.FullCompensation
        ? 0
        : x.buildGrowth * extraXpCost ),
    0,
  );

  return {
    baseRevenue,
    extraXpRevenue,
    totalRevenue: baseRevenue + extraXpRevenue,
    attendeesCount: attendees.length,
    paidAttendeesCount: paidAttendees.length,
  };
};

export default ticketRevenue;
