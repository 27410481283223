import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { faChevronDown, faHammer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import usePermission from 'src/hooks/permissions/usePermissions';
import SidebarLink from 'src/components/0100_sidebar_link';

const OrganizationSidebar: FC = () => {
  const { linkToOrganization, linkToBranch, isOrganizationPath } =
    useAppNavigations();
  const { organizationSection, isBranchManagementPath } = useAppNavigations();
  const [ isExpanded, setIsExpanded ] = useState( false );
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });

  useEffect(() => {
    if ( isOrganizationPath ) {
      setIsExpanded( true );
    }
  }, [ isOrganizationPath ]);

  return (
    <div className="gray-box-angled">
      <button
        type="button"
        className="flex justify-between w-full px-2 py-1 text-left font-bold"
        onClick={() => setIsExpanded( x => !x )}
      >
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faHammer} className="fa-fw" /> Network Tools
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'px-2 mt-1 transition-transform duration-300',
            isExpanded && '-scale-y-100',
          )}
        />
      </button>
      <div
        className={clsx(
          'gray-box-angled grid gap-2 transition-all duration-300',
          isExpanded ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
        )}
      >
        <div className="overflow-hidden grid">
          <SidebarLink
            label="Branches Overview"
            url={linkToBranch({})}
            isActive={
              organizationSection === 'branches' && !isBranchManagementPath
            }
          />

          {canManageOrganization && (
            <SidebarLink
              label="Invoices"
              url={linkToOrganization({ section: 'invoice' })}
            />
          )}

          <SidebarLink
            label="Mechanics"
            url={linkToOrganization({
              section: 'mechanics',
            })}
          />

          <SidebarLink
            label="Reports"
            url={linkToOrganization({
              section: 'reports',
            })}
          />

          <SidebarLink
            label="Settings"
            url={linkToOrganization({
              section: 'settings',
              subsection: 'special_dates',
            })}
          />

          <SidebarLink
            label="Statistics"
            url={linkToOrganization({
              section: 'statistics',
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationSidebar;
