import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useParticipationParams = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const isPaid =
    searchParams.get( 'is_paid' ) === null
      ? undefined
      : searchParams.get( 'is_paid' ) === 'true';
  const printRequested =
    searchParams.get( 'is_participating' ) === null
      ? undefined
      : searchParams.get( 'is_participating' ) === 'true';
  const isLocal =
    searchParams.get( 'is_local' ) === null
      ? undefined
      : searchParams.get( 'is_local' ) === 'true';
  const updateFilter = useCallback(
    ( key: string, value: boolean | null ) => {
      if ( value === null ) {
        searchParams.delete( key );
      } else {
        searchParams.set( key, String( value ));
      }

      setSearchParams( searchParams );
    },
    [ searchParams, setSearchParams ],
  );

  return {
    isPaid,
    printRequested,
    isLocal,
    updateFilter,
  };
};

export default useParticipationParams;
