import { gql } from 'urql';

export const getOrganizationRoles = gql`
  query GetOrganizationRoles {
    organizationRoles
  }
`;

export const getOrganization = gql`
  query GetOrganization {
    organization {
      id
      slug
      name
      lastEventAttendanceSnapshotOn
      lastEventDiffOn
      configs {
        charactersActiveCountMax
      }
    }
  }
`;

export const getOrganizationSpecialDates = gql`
  query GetOrganizationSpecialDates {
    organization {
      id
      specialDates {
        id
        title
        kind
        startsAt
        endsAt
      }
    }
  }
`;

export const getOrganizationMemberships = gql`
  query GetOrganizationMemberships($on: String) {
    organization {
      id
      memberships(on: $on) {
        id
        createdAt
        user {
          id
          fullName
          userOrganization {
            id
            role
          }
        }
        grantor {
          id
          fullName
        }
        grantingBranch {
          id
          shorthand
        }
      }
    }
  }
`;

export const getOrganizationFellowships = gql`
  query GetOrganizationFellowships($type: FellowshipKindEnum!) {
    organization {
      id
      fellowships(kind: $type) {
        id
        name
        charactersCount
        removedAt
      }
    }
  }
`;

export const getOrganizationFellowshipDetails = gql`
  query GetOrganizationFellowshipDetails($fellowshipId: Int!) {
    organization {
      id
      fellowship(fellowshipId: $fellowshipId) {
        id
        name
        description
        charactersCount
        removedAt
        characterFellowships {
          id
          rank
          removedAt
          character {
            id
            name
            user {
              id
              fullName
              userOrganization {
                id
                branch {
                  id
                  shorthand
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getOrganizationRebirthedCharacters = gql`
  query GetOrganizationRebirthedCharacters {
    organization {
      id
      rebirthedCharacters {
        id
        name
        legacyHomeGameCount
        createdAt
        user {
          id
          fullName
          userOrganization {
            id
            branch {
              id
              name
              shorthand
            }
          }
        }
        retiredCharacter {
          id
          name
        }
      }
    }
  }
`;

export const getOrganizationStatistics = gql`
  query GetOrganizationStatistics(
    $type: StatisticsTypeEnum!
    $eventId: Int
    $printRequested: Boolean
    $isPaid: Boolean
    $isLocal: Boolean
  ) {
    organization {
      id
      statistics(
        type: $type
        eventId: $eventId
        printRequested: $printRequested
        isPaid: $isPaid
        isLocal: $isLocal
      ) {
        ... on SkillsStatistics {
          eventId
          type
          values {
            id
            level
            count
          }
        }
        ... on ResidenciesStatistics {
          eventId
          type
          values {
            id
            shorthand
            name
            count
          }
        }
        ... on StatsStatistics {
          eventId
          type
          values {
            body
            mind
            resolve
            currentLife
            buildUsed
            buildEarned
            faithId
          }
        }
      }
    }
  }
`;
