import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode } from 'react';

export type TButtonState =
  | 'disabled'
  | 'enabled-highlight'
  | 'enabled-warning'
  | 'enabled'
  | 'loading'
  | 'success';
interface IProps {
  state?: TButtonState;
  defaultLabel: string | ReactNode;
  stateLabel?: {
    enabled?: string | ReactNode;
    disabled?: string | ReactNode;
    loading?: string | ReactNode;
    success?: string | ReactNode;
  };
  className?: string;
  onClick: () => void;
}

const Button: FC<PropsWithChildren<IProps>> = ({
  state = 'enabled',
  stateLabel = {},
  defaultLabel,
  className,
  children,
  onClick,
}) => {
  const isEnabled =
    state === 'enabled' ||
    state === 'enabled-highlight' ||
    state === 'enabled-warning';

  return (
    <button
      type="button"
      className={clsx(
        'px-4 py-1 rounded-xl transition-all',
        state === 'enabled' && 'bg-juno-gray-200 text-juno-gray-900',
        ( state === 'enabled-highlight' || state === 'success' ) &&
          'bg-juno-cyan-400 text-juno-gray-50',
        state === 'enabled-warning' && 'bg-juno-orange-400 text-juno-gray-50',
        ( state === 'disabled' || state === 'loading' ) &&
          'cursor-not-allowed bg-juno-gray-700 text-juno-gray-900',
        className,
        { 'cursor-not-allowed': !isEnabled },
        { 'hover:brightness-125': isEnabled },
      )}
      disabled={!isEnabled}
      onClick={onClick}
    >
      {children ||
        ( state === 'enabled-highlight' || state === 'enabled-warning'
          ? stateLabel.enabled
          : stateLabel[state]) ||
        defaultLabel}
    </button>
  );
};

export default Button;
