import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';

const OrganizationSettingsHeader: FC = () => {
  const { linkToOrganization, organizationSettingsSection } =
    useAppNavigations();

  return (
    <ResponsiveTabGroup label={startCase( organizationSettingsSection )}>
      <Tab
        label="Special Dates"
        highlightMode="responsive"
        to={linkToOrganization({
          section: 'settings',
          subsection: 'special_dates',
        })}
      />
    </ResponsiveTabGroup>
  );
};

export default OrganizationSettingsHeader;
