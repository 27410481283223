import { groupBy } from 'lodash';
import { FC } from 'react';
import Title from 'src/components/0100_title';
import skillData from 'src/components/0500_character_builders/dystopia_rising/SkillBuilder/data';
import SkillsTable from './SkillsTable';

interface IProps {
  skillsStatistics: { id: number; level: number; count: number }[];
  reputationsStatistics: { id: number; level: number; count: number }[];
}

const DystopiaRisingSkillsStatistics: FC<IProps> = ({
  skillsStatistics,
  reputationsStatistics,
}) => {
  const categoryGroups = groupBy(
    skillData.filter( x => !x.isPairwise ),
    x => x.category,
  );

  return (
    <div>
      <Title variant="heading" title="Skills" />
      <div className="max-w-[calc(100vw-16px)] overflow-auto grid gap-2">
        <div className="grid xl:flex items-end gap-2">
          <div className="grid content-between h-full">
            <div className="pb-2 xl:place-self-end">
              <SkillsTable
                isProfession
                skills={categoryGroups.default}
                skillStatistics={skillsStatistics}
                category="Default Professions"
                levels={5}
              />
            </div>
            <SkillsTable
              skills={categoryGroups.impact.filter( x => !x.isProfession )}
              skillStatistics={skillsStatistics}
              category="Impact Skills"
              levels={10}
            />
          </div>
          <div>
            <SkillsTable
              isProfession
              skills={categoryGroups.impact.filter( x => x.isProfession )}
              skillStatistics={skillsStatistics}
              category="Impact Professions"
              levels={5}
            />
          </div>
        </div>

        <div className="grid xl:flex items-start gap-2">
          <div className="grid">
            <SkillsTable
              invertAberrantSorting
              skills={categoryGroups.development.filter( x => !x.isProfession )}
              skillStatistics={skillsStatistics}
              category="Development Skills"
              levels={10}
              visibleLevels={3}
            />

            <div className="pb-2" />
            <SkillsTable
              skills={skillData.filter( x => x.isPairwise )}
              skillStatistics={reputationsStatistics}
              category="Reputations"
              levels={10}
              visibleLevels={3}
            />
          </div>
          <div>
            <SkillsTable
              isProfession
              invertAberrantSorting
              skills={categoryGroups.development.filter( x => x.isProfession )}
              skillStatistics={skillsStatistics}
              category="Development Professions"
              levels={5}
              visibleLevels={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DystopiaRisingSkillsStatistics;
