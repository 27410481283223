import { FC } from 'react';

import clsx from 'clsx';
import ProfessionTable from './ProfessionTable';
import SkillTable from './SkillTable';
import { TSkill, TSkillStatistic } from '../../types';

interface IProps {
  skills: TSkill[];
  skillStatistics: TSkillStatistic[];
  category: string;
  levels: number;
  visibleLevels?: number;
  isProfession?: boolean;
  invertAberrantSorting?: boolean;
}

const SkillsTable: FC<IProps> = ({
  category,
  levels,
  visibleLevels = levels,
  skills,
  skillStatistics,
  isProfession,
  invertAberrantSorting,
}) => (
  <table className="table-fixe\">
    <thead>
      <tr>
        <th className="text-left p-1 border border-juno-gray-700 min-w-[224px] max-w-[224px] w-[224px]">
          {category}
        </th>

        {( visibleLevels > 1 || levels > 1 ) &&
          Array.from({ length: levels }).map(( _, i ) => (
            <th
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={clsx(
                'text-center p-1 w-8 min-w-8 max-w-8 border',
                visibleLevels > i
                  ? 'border-juno-gray-700'
                  : 'border-transparent border-y-juno-gray-700 invisible',
              )}
            >
              {i + 1}
            </th>
          ))}
        <th className="text-center p-1 border border-l-4 border-juno-gray-700 w-8 min-w-8 max-w-8">
          {' '}
          Σ
        </th>
      </tr>
    </thead>
    <tbody>
      {isProfession ? (
        <ProfessionTable
          invertAberrantSorting={invertAberrantSorting}
          skills={skills.filter( x => x.isProfession )}
          skillStatistics={skillStatistics}
          levels={levels}
          visibleLevels={visibleLevels}
        />
      ) : (
        <SkillTable
          invertAberrantSorting={invertAberrantSorting}
          skills={skills.filter( x => !x.isProfession )}
          skillStatistics={skillStatistics}
          levels={levels}
          visibleLevels={visibleLevels}
        />
      )}
    </tbody>
  </table>
);

export default SkillsTable;
