import { FC } from 'react';
import clsx from 'clsx';
import { TSkill, TSkillStatistic } from '../../../types';
import TableCell from '../../TableCell';

interface IProps {
  skills: TSkill[];
  skillStatistics: TSkillStatistic[];
  levels: number;
  visibleLevels?: number;
  invertAberrantSorting?: boolean;
}

const SkillTable: FC<IProps> = ({
  invertAberrantSorting,
  levels,
  visibleLevels = levels,
  skills,
  skillStatistics,
}) => {
  const skillIds = skills.map( x => x.id );
  const maxPopulation = Math.max(
    ...skillStatistics.filter( x => skillIds.includes( x.id )).map( x => x.count ),
  );

  return skills
    .sort(( a, b ) => {
      if (( a.isAberrant && b.isAberrant ) || ( !a.isAberrant && !b.isAberrant )) {
        return a.name.localeCompare( b.name );
      }

      if ( invertAberrantSorting ) {
        return a.isAberrant ? -1 : 1;
      }

      return a.isAberrant ? 1 : -1;
    })
    .map( skill => (
      <tr key={skill.id}>
        <td
          className={clsx(
            'border border-juno-gray-700 p-1',
            skill.isAberrant && 'text-juno-cyan-200 brightness-125',
            skill.isPairwise && 'text-juno-pink-200 brightness-125',
          )}
        >
          {skill.name}
        </td>
        {( visibleLevels > 1 || levels > 1 ) &&
          Array.from({ length: levels }).map(( _, i ) => (
            <TableCell
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              visibleLevels={visibleLevels}
              level={i}
              isAberrant={skill.isAberrant}
              isPairwise={skill.isPairwise}
              category={skill.category}
              count={
                skillStatistics.find(
                  x => x.id === skill.id && x.level === i + 1,
                )?.count ?? 0
              }
              maxCount={maxPopulation}
            />
          ))}
        <TableCell
          isAberrant={skill.isAberrant}
          isPairwise={skill.isPairwise}
          category={skill.category}
          count={skillStatistics
            .filter( x => x.id === skill.id )
            .map( x => x.count )
            .reduce(( a, b ) => a + b, 0 )}
          maxCount={maxPopulation}
          className="border-l-4"
        />
      </tr>
    ));
};

export default SkillTable;
