import { useQuery } from 'urql';
import {
  IGetEventTicketsQuery,
  IGetEventTicketsQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { getEventTickets } from 'src/graphql/queries/events.graphql';
import { useParams } from 'react-router-dom';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useEventTickets = ({
  playerId,
  editMode,
}: {
  playerId?: number;
  editMode?: boolean;
}) => {
  const { eventId } = useParams();
  const { isRootUserValidated } = useAuth();
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventTicketsQuery,
    IGetEventTicketsQueryVariables
  >({
    query: getEventTickets,
    variables: { eventId: Number( eventId ), playerId, editMode },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated && Number( eventId || 0 ) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    refetch,
    tickets: data?.event?.tickets || [],
  };
};

export default useEventTickets;
