import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useAppNavigations from 'src/hooks/appNavigations/useAppNavigations';
import useRootUser from 'src/hooks/players/useRootUser';

const ReportsHeader: FC = () => {
  const {
    linkToOrganization,
    linkToCaps,
    linkToCorrectiveActions,
    reportsSection,
    organizationSection,
  } = useAppNavigations();
  const { homeBranch } = useRootUser();

  return (
    <ResponsiveTabGroup label={startCase( reportsSection )}>
      <Tab
        label="Memberships"
        to={linkToOrganization({
          section: 'reports',
          subsection: 'memberships',
        })}
        highlightMode="responsive"
        isActive={
          organizationSection === 'reports' ||
          ( reportsSection ?? 'memberships' ) === 'memberships'
        }
      />
      <Tab
        label="CAPS"
        to={linkToCaps({
          branchId: homeBranch?.id,
        })}
        highlightMode="responsive"
      />
      <Tab
        label="Corrective Actions"
        to={linkToCorrectiveActions({
          branchId: homeBranch?.id,
        })}
        highlightMode="responsive"
      />
      <Tab
        label="Character Retirements"
        to={linkToOrganization({
          section: 'reports',
          subsection: 'character_retirements',
        })}
        highlightMode="responsive"
      />
    </ResponsiveTabGroup>
  );
};

export default ReportsHeader;
