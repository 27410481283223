import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Tab from 'src/components/0200_tab';
import useAuth from 'src/hooks/auths/useAuth';
import usePermission from 'src/hooks/permissions/usePermissions';
import PlayerHeader from './PlayerHeader';
import OrganizationHeader from './OrganizationHeader';
import CharacterHeader from './CharacterHeader';
import EventsHeader from './EventsHeader';
import EventHeader from './EventHeader';
import BlueprintsHeader from './BlueprintsHeader';
import BranchHeader from './BranchHeader';
import MechanicsHeader from './MechanicsHeader';
import ReportsHeader from './ReportsHeader';
import OrganizationSettingsHeader from './OrganizationSettingsHeader';

const Header: FC = () => {
  const { openModal, isRootUserValidated } = useAuth();
  const { isPermitted: canUseDepository } = usePermission({
    action: 'create_item',
  });

  return (
    <div className="flex justify-end">
      {isRootUserValidated ? (
        <Routes>
          <Route path="/:organizationSlug">
            <Route index element={<OrganizationHeader />} />
            <Route path="branches">
              <Route index path="*" element={<OrganizationHeader />} />
              <Route path=":branchId">
                <Route index path="*" element={<BranchHeader />} />
              </Route>
            </Route>
            <Route path="players/:playerId">
              <Route path="characters">
                <Route index path="*" />
                <Route path=":characterId">
                  <Route index path="" element={<CharacterHeader showLock />} />
                  <Route path="build" element={<CharacterHeader showLock />} />
                  <Route path="*" element={<CharacterHeader />} />
                </Route>
              </Route>
              <Route index path="*" element={<PlayerHeader />} />
            </Route>
            <Route path="events">
              <Route index element={<EventsHeader />} />
              <Route path=":eventId">
                <Route index path="*" element={<EventHeader />} />
              </Route>
            </Route>

            {canUseDepository && (
              <Route path="blueprints">
                <Route index path="*" element={<BlueprintsHeader />} />
              </Route>
            )}

            <Route path="mechanics">
              <Route index path="*" element={<MechanicsHeader />} />
            </Route>

            <Route path="reports">
              <Route index path="*" element={<ReportsHeader />} />
            </Route>

            <Route path="settings">
              <Route index path="*" element={<OrganizationSettingsHeader />} />
            </Route>
          </Route>
        </Routes>
      ) : (
        <div className="flex justify-end">
          <Tab label="Sign In" onClick={() => openModal({ modal: 'login' })} />
        </div>
      )}
    </div>
  );
};

export default Header;
